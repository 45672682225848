<!-- Settings -->
<template>
    <div  v-loading="loading">       
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    v-on:keyup="fetchData(1)"
                  ></b-form-input>
                    <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
             <div class="col-sm-6 col-xs-12 text-right">
              <!-- <b-button class="rounded-1 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createPurchaseOrder">
                <i class="fa fa-plus"></i>Create Purchase Order
              </b-button> -->
             </div>        
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column fixed align="center" label='PO Number' prop="sku" sortable width="120" >
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewPurchase(scope.row.id)">{{(scope.row.po_number) ? scope.row.po_number : ""}}</a></el-tag>
                </template>
              </el-table-column>
              <el-table-column label="Supplier" sortable  min-width="200">
                <template slot-scope="scope">
                <span>{{(scope.row.supplier) ? scope.row.supplier.company_name : ""}}</span>
                </template>
              </el-table-column>            
              <el-table-column label="Request Date" prop="delivery_date" width="100" >
                <template slot-scope="scope">
                {{scope.row.request_date|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column>
               <el-table-column label="Created By"  width="150" >
                <template slot-scope="scope">
                <span>{{scope.row.created_by}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Approved By"  width="150" >
                <template slot-scope="scope">
                <span v-if="scope.row.confirm_by == 'waiting for approve'" class="red">{{scope.row.confirm_by}}</span>
                <span v-else >{{scope.row.confirm_by}}</span>
                </template>
              </el-table-column>    
              <el-table-column label="Order Status" sortable width="200" align="center">
                <template slot-scope="scope">
                
                <span v-if="scope.row.order_status == 'processing'" class="active"><b>Processing</b></span>
                <span v-if="scope.row.order_status == 'on-hold'" class="gray">On Hold</span>
                <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>
                <span v-if="scope.row.order_status == 'cancelled'" class="red"><b>Cancelled</b></span>
                <span v-if="scope.row.order_status == 'waiting'" class="red"><b>Waiting for Approve</b></span>
                <span v-if="scope.row.order_status == 'partially cancelled'" class="red">Partially Cancelled</span>
                <span v-if="scope.row.order_status == 'backorder'" class="red"><b>Back Order</b></span>
                </template>
              </el-table-column> 
              <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>
                      <el-dropdown-item :command="2" v-if="scope.row.order_status == 'waiting'|| scope.row.order_status == 'processing'" ><i class="fa fa-edit" ></i>Edit</el-dropdown-item>
                      <el-dropdown-item :command="7" v-if="scope.row.order_status != 'waiting' && scope.row.order_status != 'partially cancelled' && scope.row.order_status != 'cancelled' && scope.row.order_status != 'completed'"><i class="fa fa-edit">
                        </i>Received PO</el-dropdown-item>
                      <el-dropdown-item :command="3" v-if="scope.row.order_status != 'waiting'"><i class="fa fa-envelope"></i>Send Email</el-dropdown-item>
                       <el-dropdown-item :command="4" v-if="scope.row.order_status != 'waiting'"><i class="fa fa-download"></i>Download</el-dropdown-item> 
                      <!-- <el-dropdown-item :command="5" v-if="scope.row.order_status != 'waiting'"><i class="fa fa-trash"></i>Delete</el-dropdown-item> -->
                      <el-dropdown-item :command="6" v-if="scope.row.order_status == 'waiting'"><i class="fa fa-external-link-alt"></i>Approve</el-dropdown-item>
                      <el-dropdown-item :command="8" v-if="scope.row.order_status != 'completed' && scope.row.order_status != 'cancelled' && scope.row.order_status != 'partially cancelled'"  ><i class="fa fa-times-circle"></i>Cancel Order</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>   
            </el-table>
          </div>
        </div>
          <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[50, 100, 500, 1000]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {downloadPdf,deletePurchaseorder,getPurchaseOrderWaiting} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail'
import ApprovePO from '@/components/PurchaseOrder/approve'
import {globalEvents} from '@/utils/globalEvents';
import _ from 'lodash';
  export default {
    name: "list-purchase-order",
    data() {
      return {
        list: [],
        items: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 50,
        },
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        view_type: 'purchaseorder'
      }
    },
    watch:{
      filter : _.debounce(function(n){
      this.loading = true;
        if (!n) {
          this.fetchData(1);
        }
      }, 200)
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Purchase Orders", route: "/purchase-order/list" },
        {title: "Waiting for Approval"},       
      ]);
      globalEvents.$on("approve", (added) => {
        this.fetchData(1);
      if (added) {
        this.fetchData(1);        
      }
    });

    },
    created(){
      this.fetchData(1);
    },
    methods:{
      /*updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},*/
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
         if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        if(this.selectedWebsite){
          params.selected_website = this.selectedWebsite;
        }
        getPurchaseOrderWaiting(params).then(response => {
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })
      },

        getcustomername(customer){
            return customer.first_name+' '+customer.last_name;
        },

     onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
        createPurchaseOrder(){
        this.$router.push({path:'/purchase-order/create'})
      },
      viewPurchase(purchase_id){
         this.$router.push({path:'/purchase-order/view/'+purchase_id})
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/purchase-order/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/purchase-order/edit/'+row.id})
          }
           else if (evt === 4){            
            this.loading=true;
            downloadPdf(row.id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'purchaseorder.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          }
          else if (evt === 3){ 
           
                let customer = row.supplier;
                let order_id = row.id;
                let type = this.view_type;

            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
          else if (evt === 5){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
               deletePurchaseorder(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }else if(evt === 6){
            this.$modal.show(ApprovePO, {po_id : row.id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
           else if (evt === 7){
            this.$router.push({path:'/add/recive/purchase/order/'+row.id})
          }else if(evt === 8){
                this.$router.push({path:'/purchase/cancel/order/'+row.id})
              }
        },
    }
  }
</script>